@import 'assets/styles/variables';
.search-input {
    position: relative;
    display: flex;
    align-items: center;
  
    input {
      background: #E4E4E4;
      border-radius: 34.4041px;
      border: none;
      padding: 10px 10px 10px 50px;
      font-size: 14px;
      outline: none;
      flex-grow: 1;
      width: 390px;
      position: relative !important;
      height: 38px;
    }
    .search-suggestions {
      position: absolute;
      top: 25px;
      z-index: 99;
      background: white;
      border-radius: 10px;
      padding: 5px;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      left: 0;
      li {
        list-style-type: none;
        height: fit-content;
        padding: 10px 5px;
        font-family: "Cera Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        cursor: pointer;
        border-bottom: 1px solid #f1f1f1;
        &:hover {
          background: #ddd0f0;
          border-radius: 5px;
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #5315b5;

        &:hover {
          background: #ddd0f0;
        }
      }
    }
    .search-icon {
        display: flex;
        align-items: center;
        padding: 0px;
        position: absolute;
        left: 20px;
        top: 10px;
        z-index: 1;
    
    }
  
    .search-icon-left {
      position: absolute;
      left: 0;
    }
  
    .search-icon-right {
      position: absolute;
      right: 0;
    }
  
    .error {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }
  }
  