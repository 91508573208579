.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #5315b5;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.btnLoader {
    border: 2px solid #fff;
    border-bottom-color: #5315b5;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    height: 24px;
    width: 24px; /* Fix: Use 'width' instead of 'word-wrap' */
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
