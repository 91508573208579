@import "assets/styles/main.scss";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 99999;
  @include respond-to("small") {
    overflow: scroll;
  }
  @include respond-to("mini") {
    overflow: scroll;
  }
 
}

.modal {
  background: #fff;
  width: 80%;
  height: 90%;
  max-width: 80%; /* Set a max-width if you want to limit the width */
  max-height: fit-content; /* Set a max-height if you want to limit the height */
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  border-radius: 10px;
  @include respond-to("small") {
    height: auto;
  }
  @include respond-to("mini") {
    height: auto;
  }
}
.modal::-webkit-scrollbar {
    width: 10px;
  }
  
  .modal::-webkit-scrollbar-thumb {
    background-color: #5315B5;
    border-radius: 5px;
  }
  
  .modal::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  color: #333;
  padding: 0;
  margin: 0;
  line-height: 1;

  &:hover {
    color: #5315B5;
  }

  &:focus {
    outline: none;
  }
  @include respond-to("small") {
    top: 20px;
    right: 20px;
  }
  @include respond-to("mini") {
    top: 20px;
    right: 20px;
  }
}
