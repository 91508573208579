@import "../../assets/styles/main.scss";
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5315b5;
  border-radius: 19.6841px;
  height: 40px;
  font-family: "Cera Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  border: none;
  padding: 0 20px;
  cursor: pointer;
  width: 100%;
  position: relative;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.left {
      margin-right: 10px;
    }
  }
  .left {
    position: absolute;
    left: 20px;
    // justify-content: flex-start;
    display: flex;
    align-self: center;
  }
  .right {
    position: absolute;
    right: 30px;
    // justify-content: flex-start;
    display: flex;
    align-self: center;
  }
}

.previousStepBtn {
  @extend .button;
  background: none;
  border: 1px solid #5315b5;
  color: #5315b5;
}
.dangerPreviousStepBtn{
  @extend .button;
  background: none;
  border: 1px solid red;
  color: red;
}
.adjacent-icon-btn {
  background: none;
  color: #5315b5;
  width: fit-content;
  justify-content: flex-start;
  .button-icon {
    position: relative;
    margin-right: 12px;
    display: flex;
  }
  .left {
    position: absolute;
    left: 0px;
    justify-content: flex-start;
  }
}
