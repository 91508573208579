@import "assets/styles/main.scss";

.cookie-modal {
  .modal {
    width: 500px;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    overflow-y: auto;
    @include respond-to("small") {
      width: 80%;
      max-height: 90vh;
    }
    h2 {
      text-align: start;
      font-size: 24px;
      font-family: "Cera Pro Normal";
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      padding: 0;
      margin-top: 20px;
      @include respond-to("small") {
        font-size: 20px;
      }
    }
    img {
      width: fit-content;
      height: 50px;
      object-fit: contain;
      margin-top: 40px;
      @include respond-to("small") {
        margin-top: 20px;
      }
    }
    p {
      text-align: center;
      font-size: 16px;
      font-family: "Cera Pro";
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @include respond-to("small") {
        font-size: 12px;
      }
    }
    .cookiesBtn {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      button {
        width: 100%;
      }
    }
    .cookie-settings {
      width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      h3 {
        text-align: start;
        font-size: 24px;
        font-family: "Cera Pro Normal";
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        margin-bottom: 10px;
        @include respond-to("small") {
          font-size: 20px;
        }
      }
      img {
        display: none;
        width: fit-content;
        height: 50px;
        object-fit: contain;
        align-self: center;
      }
      label {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
        font-family: "Cera Pro Normal";
        @include respond-to("small") {
          font-size: 14px;
        }
        input {
          accent-color: #5315b5;
        }
        p {
          margin: 5px 0 0 20px;
          font-size: 14px;
          font-weight: 400;
          font-size: 12px;
          font-family: "Cera Pro";
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-align: start;
          @include respond-to("small") {
            font-size: 12px;
          }
        }
      }
    }
  }
}
