@font-face {
  font-family: 'Cera Pro Bold';
  src: url('../fonts/Cera Pro Bold.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro Medium';
  src: url('../fonts/Cera Pro Medium.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro Normal';
  src: url('../fonts/Cera Pro Black.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/Cera Pro Light.otf') format('truetype');
  font-display: swap;
}
