@import "assets/styles/main.scss";

.main-footer {
  display: flex;
  //   background: #fff;
  padding: 20px 100px;
  gap: 20px;
  flex-wrap: no-wrap; /* allow sections to wrap to next line if necessary */
  align-items: stretch; /* align sections to stretch to full height of parent */
  max-width: 1920px;
  margin: auto;
  margin-top: 60px;
  @include respond-to("small") {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
  }
  .col {
    flex: 1; /* allow sections to expand to fill available space */
    box-sizing: border-box; /* include padding and border in width calculation */
  }
  a {
    text-decoration: none;
  }
  .section-one {
    display: flex;
    width: 45%;
    flex-direction: column;
    @include respond-to("small") {
      width: 100%;
      padding: 0px 10px;
      margin-bottom: 20px;
    }
    .description {
      font-family: "Cera Pro";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 7px;
      color: #8b9095;
      margin-top: 10px;
      width: 300px;
    }
    .follow {
      display: flex;
      flex-direction: column;
      margin-top: 140px;
      @include respond-to("small") {
        margin-top: 40px;
      }
      .footerHeading {
        /* Body Copy / B2 */

        font-family: "Cera Pro Medium";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        color: #919296;
      }
      .socialShare {
        display: flex;
        margin-top: 12px;
        span {
          display: flex;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: rgba(83, 21, 181, 0.07);
          margin-right: 10px;
          cursor: pointer;

          a {
            display: flex;
            text-decoration: none;
            img {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
  .section-two {
    width: 30%;
    padding: 0px 0px 0px 35px;
    @include respond-to("small") {
      width: 100%;
      padding: 0px 10px;
      margin-bottom: 20px;
    }
    .heading {
      font-family: "Cera Pro Medium";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;

      color: #6a7177;
    }
    .footer-menu {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      a {
        font-family: "Cera Pro";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 7px;
        color: #8b9095;
      }
    }
  }
  .section-three {
    @extend .section-two;
    @include respond-to("small") {
      width: 100%;
      padding: 0px 10px;
      margin-bottom: 20px;
    }
    width: 10%;
    padding: 0px;
  }
  .section-four {
    width: 25%;
    .heading {
      font-family: "Cera Pro Medium";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;

      color: #6a7177;
    }
    .description {
      margin-top: 20px;
    }
    @extend .section-one;
    hr {
      width: 100%;
      height: 0;
      border: 1px solid #f3f3f3;
    }

    .importantLinks {
      margin-top: 25px;
      display: flex;
      justify-content: space-around;
      align-items: first baseline;
      .dot {
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background: #6d7073;
      }
      a {
        font-family: "Cera Pro Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        color: #6d7073;
      }
    }
  }
}
hr {
  width: 85%;
  border: 1.28664px solid #f3f3f3;
}
.copyrights {
  text-align: center;
  padding: 10px 0px;
  font-family: "Cera Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #8b9095;
}
