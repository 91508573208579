@mixin margin-utilities($property, $directions...) {
  @for $value from 1 through 100 {
    .#{$property}-#{$value} {
      @each $direction in $directions {
        #{$direction}: $value + px;
      }
    }
  }
}

@include margin-utilities(mr, margin-right);
@include margin-utilities(ml, margin-left);
@include margin-utilities(mt, margin-top);
@include margin-utilities(mb, margin-bottom);
