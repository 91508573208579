$breakpoints: (
    'mini': (max-width: 320px),
    'extra-small': (max-width: 575px),
    'small': (max-width: 767px),
    'medium': (max-width: 992px),
    'large': (max-width: 1280px),
    'larger': (min-width: 1920px)) !default;

@mixin respond-to($breakpoint) {

    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {

        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " 
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}