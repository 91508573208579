@import "assets/styles/variables";
@import "assets/styles/main.scss";

.main-header {
  height: 80px; /* Adjust as necessary */
  background: $white;
  box-shadow: 0px 18.6355px 37.271px rgba(0, 0, 0, 0.06);
  padding: 0 100px; /* 100px left and right padding */
  max-width: 1920px;
  margin: auto;
  display: flex;
  align-items: center;
  @include respond-to("small") {
    flex-direction: column;
    padding: 0 10px;
    justify-content: center;
    margin: 0px 0px;
  }
  justify-content: space-between;
  .left {
    display: flex;
    width: 30%;
    justify-content: flex-start;
    align-items: center;
    .burgerMenu {
      position: relative;
      cursor: pointer;
      padding: 10px;
      border-radius: 10px 10px 0 0;
      @include respond-to("small") {
        order: 2;
      }
      .main-menu {
        position: absolute;
        top: 40px;
        left: 0;
        opacity: 0; /* Start fully transparent */
        visibility: hidden; /* Hide by default */
        display: flex; /* Keep display flex but control visibility with opacity */
        flex-direction: column;
        gap: 10px;
        z-index: 999;
        background: white;
        width: 200px;
        padding: 20px;
        border-top: 10px solid #5315b5;
        border-radius: 0 10px 10px 10px;
        transition: opacity 0.5s ease, visibility 0.5s;
        @include respond-to("small") {
          right: 0;
          left: auto;
          border-radius: 10px 0px 10px 10px;
        }
        /* Smooth transition for opacity and visibility */
        a {
          text-decoration: none;
          font-family: "Cera Pro";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 7px;
          color: #000;
        }
      }
      &:hover {
        background: #5315b5;
        .main-menu {
          opacity: 1; /* Fully visible */
          visibility: visible; /* Make it visible */
        }
      }
    }

    .logo {
      margin-left: 30px;
      cursor: pointer;
      @include respond-to("small") {
        margin-left: 0px;
      }
      img {
        height: 35px;
        width: auto;
        object-fit: cover;
      }
    }
    @include respond-to("small") {
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }
  .right {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include respond-to("small") {
      display: none;
    }
    .divider {
      height: 34px;
      margin: 0px 13px 0px 24px;
      border-left: 0.5px solid #000000;
    }
    .companyButton {
      width: fit-content;
      button {
        display: flex;
        gap: 10px;
        padding: 10px 30px 10px 15px;

        .right {
          right: 10px;
        }
      }
    }
    .user-icon {
      margin: 0px 15px 0px 21px;

      .dropdown {
        position: relative;
      }

      .dropdown-content {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 7px;
        background: #fff;
        width: 150px;
        padding: 10px 10px;
        box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.1);
        opacity: 0; /* Set initial opacity to 0 */
        visibility: hidden; /* Hide the dropdown by default */
        transition: opacity 0.3s ease, visibility 0.3s ease; /* Add transition for opacity and visibility */
        z-index: 999;

        & > span {
          color: #606060;
          font-family: "Poppins";
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          transition: opacity 0.3s ease; /* Add transition for opacity */
        }
      }

      &:hover .dropdown-content {
        opacity: 1; /* Change opacity to 1 when user-icon is hovered */
        visibility: visible; /* Show the dropdown */
      }
    }

    .user-icon,
    .wishlist-icon {
      cursor: pointer;
    }
  }
}
.home-header {
  @extend .main-header;
  box-shadow: none;
  background: none;

  padding: 0px 100px;
  margin: 0px;
  @include respond-to("small") {
    padding: 0px 10px;
  }
  .right {
    .divider {
      border-left: 0.5px solid #fff;
    }
  }
}
.sticky {
  .right {
    .divider {
      border-left: 0.5px solid #000;
    }
  }
}
.main-header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 999;
  animation: fadeIn 0.5s ease-in-out;
  margin: auto;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
